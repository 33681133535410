import { Injectable } from "@angular/core";
import { getFirestore, getDoc, collection, doc, setDoc, Firestore, DocumentReference, onSnapshot, updateDoc, arrayUnion, arrayRemove, runTransaction, where, query, getDocs, QuerySnapshot, and } from "firebase/firestore";
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Unsubscribe, User } from "firebase/auth";
import { UserInterface } from "../entities/user";
import { FirebaseConfigService } from "./firebase.config.service";
import { BehaviorSubject, Observable } from "rxjs";
import { StripeService } from "./stripe.service";
import { SmartlookService } from "./smartlook.service";

@Injectable({providedIn: "root"})
export class UserService {  

    private _user!: UserInterface;
    _userSubject: BehaviorSubject<UserInterface> = new BehaviorSubject<UserInterface>(this._user);
    public get user(): Observable<UserInterface>{return this._userSubject.asObservable()};
    public get userSnapShot(): UserInterface {return this._user};

    public isUserPremium: boolean = false;

    public app: FirebaseApp;
    db: Firestore;
    private readonly userCollectionName = 'Users';

    constructor(private firebaseConfigService: FirebaseConfigService, private stripeService: StripeService,
        private smartlookService: SmartlookService
    ){
        this.app = this.firebaseConfigService.app; 
        this.db = getFirestore(this.app,'playlist-party');
    }

    getUserDocRef(id: string):  DocumentReference{
        return doc(this.db, this.userCollectionName, id);
    }
    
    async initialiseUser(authuser: User) {
        const userDocRef = this.getUserDocRef(authuser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()){
            this._user = userDoc.data() as UserInterface;
            //Check on the users premium status and update it in firestore
            this.stripeService.checkPremiumStatus(this._user).then(isPremium => {
                this.isUserPremium = isPremium;
                setDoc(userDocRef, {premium: isPremium}, {merge: true});
            });
        } else {
            this._user =  {
                email: authuser.email,
                firstName: authuser.displayName,
                id: authuser.uid,
                sitesAccessed: [],
                premium: false,
            };
            await setDoc(userDocRef,this._user);
        }

        this.smartlookService.initSmarlookSessionForUser(this._user);        
        this._userSubject.next(this._user);
    }

    async setUser(user: UserInterface){
        let user_docRef = this.getUserDocRef(user.id);
        await setDoc(user_docRef, user);
    }

    ownsPlaylist(playlistOwnerId: string): boolean{
        return this._user.id == playlistOwnerId;
    }

    updateSitesAccessed(user_id: string, site_id: string){
        let userDocRef = this.getUserDocRef(user_id);
        updateDoc(userDocRef, {sitesAccessed: arrayUnion(site_id)});
    }
}
