import { Injectable } from '@angular/core';
import { FirebaseConfigService } from './firebase.config.service';
import { Router } from '@angular/router';
import { FirebaseApp } from 'firebase/app';
import { UserInterface } from '../entities/user';
import { getFunctions, httpsCallable } from 'firebase/functions';

import {
    getFirestore,
    doc,
    Firestore,
    getDoc,
    setDoc,
    collection,
    addDoc,
    onSnapshot,
    query,
    where
  } from 'firebase/firestore';
import { SiteService } from './site.service';



export interface PortalData{
    url: string;
}

@Injectable({
  providedIn: 'root'
})
export class StripeService {
    private app: FirebaseApp;
    private db: Firestore;
    private monthly_premium_subscription_product: string = "";
  constructor( private router: Router, private firebaseConfigService: FirebaseConfigService, private siteService: SiteService) {
    this.app = this.firebaseConfigService.app;
    this.db = getFirestore(this.app,'(default)');
  }

  async setupCheckout(currentUser: UserInterface, yearly: boolean) {

    const priceId = yearly ? this.siteService.yearly_premium_subscription_product : this.siteService.monthly_premium_subscription_product;

    const checkoutSessionsCollection = collection(this.db, 'StripeCustomers', currentUser.id, 'checkout_sessions');
    const docRef = await addDoc(checkoutSessionsCollection, {
      price: priceId,
      success_url: window.location.origin + '/upgrade',
      allow_promotion_codes: true,
      cancel_url: window.location.origin + '/upgrade',
    });
  
    // Wait for the CheckoutSession to get attached by the extension
    onSnapshot(docRef, (snap) => {
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.href = url;
      }
    });
  }


    async setupPortal(currentUser: UserInterface) {
        const functions = getFunctions(this.app, 'us-central1');
        const functionRef = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
        const { data } = await functionRef({
            customerId: currentUser.id,
            returnUrl: window.location.origin + '/upgrade',
            locale: "auto", // Optional, defaults to "auto"
        });
        const portalData = data as PortalData;
        window.location.assign(portalData.url);
    }

  async checkPremiumStatus(currentUser: UserInterface): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const subscriptionsCollection = collection(this.db, 'StripeCustomers', currentUser.id, 'subscriptions');
      const q = query(subscriptionsCollection, where('status', 'in', ['trialing', 'active']));
  
      onSnapshot(q, (snapshot) => {
        // In this implementation we only expect one active or trialing subscription to exist.
        const doc = snapshot.docs[0];
        if (doc) {
          resolve(true);
        } else {
          resolve(false);
        }
      }, reject);
    });
  }
}
