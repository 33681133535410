<ion-app id="app">
  <ion-content class="{{siteService.current_site_id}}">
    <ion-split-pane contentId="main-content" when="(min-width: 1300px)">
      <ion-menu id="menu"  contentId="main-content" type="overlay">
        <ion-menu-toggle id="menu-list" auto-hide="false">
          <ion-list>
            <ion-list-header class="website-title">
              <!--Website Icon and Logo-->
              <div class="website-logo">
                <ion-img src="{{siteService.siteLogo}}" class="menu-logo"></ion-img>
                <h2>{{siteService.site_title}}</h2>
              </div>
              <p>by LingDisco®</p>
            </ion-list-header>
            <ion-item class="menu-item" *ngIf="user&&user.email">
              <ion-icon class="icon" color="white" aria-hidden="true" name="person-outline" slot="start"></ion-icon>
              <ion-label class="menu-label">{{user.firstName || user.email}}</ion-label>
            </ion-item>
            <div class="menu-border"></div>
            <ion-item class="menu-item-clickable" lines="none" routerLink="home" routerLinkActive="selected" >
              <ion-icon class="icon" color="white" aria-hidden="true" name="home" slot="start"></ion-icon>
              <ion-label class="menu-label">Home</ion-label>
            </ion-item>
            <ion-item class="menu-item-clickable" lines="none" routerLink="lessons" routerLinkActive="selected" >
              <ion-icon class="icon" color="white" aria-hidden="true" name="book" slot="start"></ion-icon>
              <ion-label class="menu-label">Lessons</ion-label>
            </ion-item>
            <ion-item *ngIf="songOfTheDay" class="menu-item-clickable" lines="none" (click)="goToSongOfDay()">
              <ion-icon class="icon" color="white" aria-hidden="true" name="musical-notes" slot="start"></ion-icon>
              <ion-label class="menu-label">Song of The Day!</ion-label>
            </ion-item>
            <ion-item class="menu-item-clickable" lines="none" (click)="createPlaylist()">
              <ion-icon class="icon" color="white" aria-hidden="true" name="add-circle-outline" slot="start"></ion-icon>
              <ion-label class="menu-label">Create A Playlist!</ion-label>
            </ion-item>
            <div class="bottom-buttons">
              <ion-item class="menu-item-clickable" lines="none" routerLink="upgrade" routerLinkActive="selected" >
                <ion-img class="icon-logo" src="{{siteService.siteLogo}}" slot="start"></ion-img>
                <ion-label class="menu-label">{{ user && user.premium ? "Manage Subscription":"Unlock Premium!"}}</ion-label>
              </ion-item>
              <ion-item class="menu-item-clickable auth-button" lines="none" href="mailto:support@lingdisco.com">
                <ion-icon class="icon" color="white" aria-hidden="true" name="help-circle-outline" slot="start"></ion-icon>
                <ion-label class="menu-label">Help & Feedback</ion-label>
              </ion-item>
              <ion-item class="menu-item-clickable auth-button" lines="none" (click)="logOut()" *ngIf="user&&user.email">
                <ion-icon class="icon" color="white" aria-hidden="true" name="log-out" slot="start"></ion-icon>
                <ion-label class="menu-label">Sign Out</ion-label>
              </ion-item>
              <ion-item class="menu-item-clickable auth-button" lines="none" routerLink="sign-in" *ngIf="!(user&&user.email)" routerLinkActive="selected" >
                <ion-icon class="icon" color="white" aria-hidden="true" name="log-in" slot="start"></ion-icon>
                <ion-label class="menu-label">Log In</ion-label>
              </ion-item>
            </div>
          </ion-list>
        </ion-menu-toggle>
      </ion-menu>
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-split-pane>
  </ion-content>
</ion-app>