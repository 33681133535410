
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SiteService {

    public current_site_id: string = "";
    public site_title: string = "";
    public siteLogo: string = "";
    public monthly_premium_subscription_product: string = "";
    public yearly_premium_subscription_product: string = "";
    public site_id_map: { [key: string]: string } = {
        "spanishplaylistparty.com": "es",
        "frenchplaylistparty.com": "fr",
        "germanplaylistparty.com": "de",
        "italianplaylistparty.com": "it",
        "portugueseplaylistparty.com": "pt",
        "dutchplaylistparty.com": "nl",
        "swedishplaylistparty.com": "sv",
        "danishplaylistparty.com": "da",
        "norwegianplaylistparty.com": "no",
        "finnishplaylistparty.com": "fi",
        "russianplaylistparty.com": "ru",
        "polishplaylistparty.com": "pl",
        "greekplaylistparty.com": "el",
        "turkishplaylistparty.com": "tr",
        "arabicplaylistparty.com": "ar",
        "hebrewplaylistparty.com": "he",
        "localhost": "es"
    }

    private site_title_map: { [key: string]: string } = {
        "spanishplaylistparty.com": "SpanishPlaylistParty",
        "frenchplaylistparty.com": "FrenchPlaylistParty",
        "germanplaylistparty.com": "GermanPlaylistParty",
        "italianplaylistparty.com": "ItalianPlaylistParty",
        "portugueseplaylistparty.com": "PortuguesePlaylistParty",
        "dutchplaylistparty.com": "DutchPlaylistParty",
        "swedishplaylistparty.com": "SwedishPlaylistParty",
        "danishplaylistparty.com": "DanishPlaylistParty",
        "norwegianplaylistparty.com": "NorwegianPlaylistParty",
        "finnishplaylistparty.com": "FinnishPlaylistParty",
        "russianplaylistparty.com": "RussianPlaylistParty",
        "polishplaylistparty.com": "PolishPlaylistParty",
        "greekplaylistparty.com": "GreekPlaylistParty",
        "turkishplaylistparty.com": "TurkishPlaylistParty",
        "arabicplaylistparty.com": "ArabicPlaylistParty",
        "hebrewplaylistparty.com": "HebrewPlaylistParty",
        "localhost": "SpanishPlaylistParty"
    }

    private site_logo_map: { [key: string]: string } = {
        "spanishplaylistparty.com": "assets/logos/spanish-logo.png",
        "frenchplaylistparty.com": "assets/logos/french-logo.png",
        "germanplaylistparty.com": "assets/logos/german-logo.png",
        "italianplaylistparty.com": "assets/logos/italian-logo.png",
        "portugueseplaylistparty.com": "assets/logos/portuguese-logo.png",
        "dutchplaylistparty.com": "assets/logos/dutch-logo.png",
        "swedishplaylistparty.com": "assets/logos/swedish-logo.png",
        "danishplaylistparty.com": "assets/logos/danish-logo.png",
        "polishplaylistparty.com": "assets/logos/polish-logo.png",
        "arabicplaylistparty.com": "assets/logos/arabic-logo.png",
        "hebrewplaylistparty.com": "assets/logos/hebrew-logo.png",
        "localhost": "assets/logos/spanish-logo.png"
    }

    private monthly_premimum_product_map: { [key: string]: string } = {
        "spanishplaylistparty.com": "price_1OvPsuKddN2ByNkRJNYf3htL",
        "localhost": "price_1OvPsuKddN2ByNkRJNYf3htL"
    }
    private yearly_premimum_product_map: { [key: string]: string } = {
        "spanishplaylistparty.com": "price_1P9WTWKddN2ByNkRnJqwrV33",
        "localhost": "price_1P9WTWKddN2ByNkRnJqwrV33"
    }

    constructor() {}

    //Set site ID
    setSiteID(site_domain: string){
        this.current_site_id = this.site_id_map[site_domain];
        this.site_title = this.site_title_map[site_domain];
        this.siteLogo = this.site_logo_map[site_domain];
        this.monthly_premium_subscription_product = this.monthly_premimum_product_map[site_domain];
        this.yearly_premium_subscription_product = this.yearly_premimum_product_map[site_domain];
    }
    
}

