import { Injectable } from '@angular/core';
import Smartlook from 'smartlook-client';
import { UserInterface } from '../entities/user';

@Injectable({ providedIn: 'root' })
export class SmartlookService {
  private readonly smartLookProjectKey = "497842bca58f4f436089ac893e48099033ac8834";
  constructor() {}

  public initSmarlookSessionForUser(user: UserInterface) {
    Smartlook.identify(user.id, {
      name: user.firstName,
      email: user.email,
    });

    Smartlook.track('user-logged-in', {
      name: user.firstName,
      email: user.email,
    });

    Smartlook.init(this.smartLookProjectKey);
  }
}
