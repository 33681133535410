<ion-toolbar class="modal-toolbar">
  <ion-title slot="start">Create a New Playlist</ion-title>
  <ion-buttons slot="end">
    <ion-button (click)="cancel()">Cancel</ion-button>
  </ion-buttons>
</ion-toolbar>
<div class="sign-up-outer-frame">
  <div class="sign-up-inner-frame">
    <ion-grid class="sign-up-grid">        
      <ion-row>
        <ion-col size="1200" class="ion-text-center">
          <h1>Enter Playlist Info:</h1>
        </ion-col>
      </ion-row>
      <ion-row class="playlist-form-row">
        <ion-col class="playlist-form-col" size="1200">     
          <form class="playlist-form" [formGroup]="playlistForm" (ngSubmit)="createPlaylist()">
            <ion-input formControlName="name" 
              label="Playlist Name"
              labelPlacement="floating"
              errorText="Playlist name is not valid"
            ></ion-input>
            <ion-input 
              formControlName="description" 
              label="Playlist Description"
              labelPlacement="floating"
              errorText="Description is not valid"
            ></ion-input>
            <ion-button class="playlist-form-button" color="secondary" expand="block" fill="solid" type="submit" [disabled]="playlistForm.invalid">
              Create Playlist!
            </ion-button>
          </form>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</div>
