import { Injectable } from "@angular/core";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, Auth, signInWithCredential } from "firebase/auth";

import { BehaviorSubject, Observable, Subject } from "rxjs";

import { FirebaseApp, initializeApp } from 'firebase/app';
import { environment } from "src/environments/environment";
import { UserSignUpInfo, UserSignInInfo } from "../entities/user";
import { FirebaseConfigService } from "./firebase.config.service";
import { UserService } from "./user.service";

import { AlertController } from "@ionic/angular";
import { ActivatedRoute, Router } from "@angular/router";
import {authErrors, FirebaseError, getErrorDetail} from "src/app/services/firebase.errors"
import { SiteService } from "./site.service";

@Injectable({providedIn: 'root'})
export class AuthService{
    public get auth(): Auth {return getAuth()}
    public app: FirebaseApp;
    private _hasEverBeenInit: boolean = false;
    private authSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public get isLoggedInSnapShot(): Observable<boolean>{return this.authSubject.asObservable()};

    public siteId: string = "";
    constructor(private firebaseConfigService: FirebaseConfigService, private userService: UserService, 
        private alertController: AlertController, private router: Router,
        private siteService: SiteService){
        this.app = this.firebaseConfigService.app;
        this.siteId = this.siteService.site_id_map[window.location.hostname];
        console.log("site id is " + this.siteId);
        this.auth.onAuthStateChanged(
            async (user) => {
                if (user){     
                    if (user){
                        console.log("setting up user")
                        await this.userService.initialiseUser(user);
                    }       
                }
                this._hasEverBeenInit = true;
                this.authSubject.next(user ? true : false);
            }
        );
    }
    

    async signUpWithEmail(userSignUpInfo: UserSignUpInfo){
        const auth = getAuth(this.app);
        try{
            const authRes = await createUserWithEmailAndPassword(auth, userSignUpInfo.email, userSignUpInfo.password).then((userCredential) => {
                //Navigate to home when signed in
                let user_id = userCredential.user.uid;
                let new_user = {
                    email: userSignUpInfo.email,
                    firstName: userSignUpInfo.firstName,
                    id: user_id,
                    sitesAccessed: [this.siteId],
                    premium: false,
                }
                this.userService.setUser(new_user);
                this.router.navigate(['/home']);
        })
        }catch(error: any){
            console.log(error);
            console.log(getErrorDetail(error));
            let alert = await this.alertController.create(
                {
                    header: 'Sign up',
                    subHeader: 'Sign up error',
                    message: getErrorDetail(error),
                    buttons: ['OK'],
                }
            );
            alert.present();
            throw error;
        }
    }

    async signInWithEmail(userSignInInfo: UserSignInInfo){
        // TODO add alert on problems
        try{
            const authRes = await signInWithEmailAndPassword(this.auth, userSignInInfo.email, userSignInInfo.password).then((userCredential) => {
                //Navigate to home when signed in
                let user_id = userCredential.user.uid;
                this.userService.updateSitesAccessed(user_id, this.siteId);
                this.router.navigate(['/home']);
              })
        }catch(error: any){
            console.log(error);
            console.log(getErrorDetail(error));
            let alert = await this.alertController.create(
                {
                    header: 'Sign in',
                    subHeader: 'Sign in error',
                    message: getErrorDetail(error),
                    buttons: ['OK'],
                }
            );
            alert.present();
            throw error;
        }
    }

    async signUpWithGoogle() {
        const auth = getAuth(this.app);
        const provider = new GoogleAuthProvider();
      
        try {
          const userCredential = await signInWithPopup(auth, provider);
          // Navigate to home when signed in
          let user_id = userCredential.user.uid;
          let new_user = {
            email: userCredential.user.email,
            firstName: userCredential.user.displayName,
            id: user_id,
            sitesAccessed: [this.siteId],
            premium: false,
          }
          this.userService.setUser(new_user);
          this.router.navigate(['/home']);
        } catch (error: any) {
          console.log(error);
          console.log(getErrorDetail(error));
          let alert = await this.alertController.create(
            {
              header: 'Sign up',
              subHeader: 'Sign up error',
              message: getErrorDetail(error),
              buttons: ['OK'],
            }
          );
          alert.present();
          throw error;
        }
      }

    async signInWithGoogle(){
        const provider = new GoogleAuthProvider();
        await signInWithPopup(this.auth, provider).then((userCredential) => {
            //Navigate to home when signed in
            let user_id = userCredential.user.uid;
            this.userService.updateSitesAccessed(user_id, this.siteId);
        });
        this.router.navigate(['/home']);
    }
    
    async logout(){
        await this.auth.signOut();
        this.userService._userSubject.next({} as any);
    }

}
