import { FirebaseApp } from 'firebase/app';
import {
  getFirestore,
  doc,
  Firestore,
  getDoc,
  setDoc,
  collection,
  onSnapshot
} from 'firebase/firestore';
import { FirebaseConfigService } from './firebase.config.service';
import { Song } from '../entities/song';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SongService {
    private app: FirebaseApp;
    private db: Firestore;
    private readonly songCollectionName = 'Songs';

    private _selected_song: Song = null;
    public get selected_song_snapshot() {return this._selected_song}

    private _new_selected_song_subject: BehaviorSubject<Song> = new BehaviorSubject<Song>(null);
    private _selected_song_subject: BehaviorSubject<Song> = new BehaviorSubject<Song>(null);

    public get new_song() {return this._new_selected_song_subject.asObservable()}

    public get selected_song() {return this._selected_song_subject.asObservable()}

    private firestore_subscription: Subscription;



    public async setSong(songId: string) {
        if (this._selected_song){
            if(this._selected_song.id == songId){
                return this._selected_song;
            }else {
                if(this.firestore_subscription && !this.firestore_subscription.closed){
                    this.firestore_subscription.unsubscribe();
                }
            }
        }
        this._new_selected_song_subject.next(this._selected_song);
        this._selected_song_subject.next(this._selected_song);
        this.firestore_subscription = this.getSongObservable(songId).subscribe(
            music => {
                this._selected_song = music;
                this._selected_song_subject.next(this._selected_song);
                //this.updateDetailsForMusic();
                //this.updateLessonsForMusic()
            }
        );
        return this._selected_song;
    }

    constructor(private firebaseConfigService: FirebaseConfigService) {
        this.app = this.firebaseConfigService.app;
        this.db = getFirestore(this.app,'playlist-party');
    }

    //Get song by ID
    async getSongById(songId: string): Promise<Song> {
        const docRef = doc(this.db, this.songCollectionName, songId);
        const docSnap = await getDoc(docRef);
        return docSnap.data() as Song;
    }
    //Get Song Observable
    getSongObservable(songId: string){
        if(!songId){
            return null;
        }
        let subject = new Subject<Song>();
        const unsub = onSnapshot(doc(this.db, this.songCollectionName, songId),  (doc) => {
            subject.next(doc.data() as Song)
        });
        return subject.asObservable();
    }
    //Add Song for the first time
    async addSong(song: Song): Promise<Song> {
        // Add a new document and save it using it's spotify ID
        const docRef = doc(this.db, this.songCollectionName, song.id);
        //Check if doc exists
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return docSnap.data() as Song;
        }
        //Save doc
        song.explicit_label = "unknown";
        await setDoc(docRef, song, { merge: true });
        return song;
    }

    //Convert SongSuggestion to Song
    async convertSongSuggestionToSong(songSuggestion: any): Promise<Song> {
        const song: Song = {
            id: songSuggestion.id,
            name: songSuggestion.name,
            artists: songSuggestion.artists,
            imgURL: songSuggestion.imgURL,
        }
        return song;
    }

}
