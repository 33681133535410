import { FirebaseApp } from 'firebase/app';
import {
  getFirestore,
  doc,
  DocumentSnapshot,
  DocumentData,
  Firestore,
  onSnapshot,
  collection,
  setDoc,
  query,
  where,
  or,
  and,
  runTransaction,
  Timestamp,
  orderBy,
  getDocs,
  deleteDoc,
  getCountFromServer,
  updateDoc,
  arrayRemove,
  arrayUnion,
  deleteField,
  getDoc
} from 'firebase/firestore';
import { FirebaseConfigService } from './firebase.config.service';
import { Playlist, SongSuggestionRef, PlaylistCreationInfo, PlaylistSnapshot } from '../entities/playlist';
import { Injectable } from '@angular/core';
import { UserInterface } from '../entities/user';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { SongService } from './song.service';
import { Song } from '../entities/song';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class PlaylistService {
    private app: FirebaseApp;
    private db: Firestore;
    private readonly userPlaylistCollectionName = 'Playlists';

    
    private _selected_playlist: PlaylistSnapshot = null;
    public get selected_playlist_snapshot() {return this._selected_playlist}

    private _new_selected_playlist_subject: BehaviorSubject<PlaylistSnapshot> = new BehaviorSubject<PlaylistSnapshot>(null);
    private _selected_playlist_subject: BehaviorSubject<PlaylistSnapshot> = new BehaviorSubject<PlaylistSnapshot>(null);

    public get new_playlist() {return this._new_selected_playlist_subject.asObservable()}

    public get selected_playlist() {return this._selected_playlist_subject.asObservable()}

    private firestore_subscription: Subscription;

    public selectedSongIndex: number;

    //Playlist Song Queue
    public playlistQueue: Song[] = [];
    public next_song: Song;
    public prev_song: Song;

    //Is User Premium
    public user: UserInterface;


    public async setPlaylist(playlistId: string) {
        if (this._selected_playlist){
            if(this._selected_playlist.id == playlistId){
                return this._selected_playlist;
            }else {
                if(this.firestore_subscription && !this.firestore_subscription.closed){
                    this.firestore_subscription.unsubscribe();
                }
            }
        }
        this._new_selected_playlist_subject.next(this._selected_playlist);
        this._selected_playlist_subject.next(this._selected_playlist);
        this.firestore_subscription = this.getPlaylistObservable(playlistId).subscribe(
            playlist => {
                this.setUpSelectedPlaylistSnapshot(playlist).then(
                    snapshot => {
                        this._selected_playlist = snapshot;
                        this._selected_playlist_subject.next(this._selected_playlist);
                    }
                );
            }
        );
        return this._selected_playlist;
    }

    //Get Song Observable
    getPlaylistObservable(playlistId: string):Observable<Playlist>{
        if(!playlistId){
            return null;
        }
        let subject = new Subject<Playlist>();
        const unsub = onSnapshot(doc(this.db, this.userPlaylistCollectionName, playlistId),  (doc) => {
            subject.next(doc.data() as Playlist)
        });
        return subject.asObservable();
    }

    constructor(
    private firebaseConfigService: FirebaseConfigService,
    private songService: SongService,
    private userService: UserService
    ) {
        this.app = this.firebaseConfigService.app;
        this.db = getFirestore(this.app,'playlist-party');
        this.userService.user.subscribe(
            user => {
                this.setUser(user);
            }
        );
    }

  //Get playlists by discover category
    async getPlaylistsByDiscoverCategory(discoverCategory: string): Promise<Playlist[]> {
        const q = query(collection(this.db, this.userPlaylistCollectionName), where('discover_category', '==', discoverCategory));
        const querySnapshot = await getDocs(q);
        const playlists: Playlist[] = [];
        querySnapshot.forEach((doc) => {
            playlists.push(doc.data() as Playlist);
        });
        return playlists;
    }

    //Get playlists by user
    async getPlaylistsByUser(userId: string): Promise<Observable<Playlist[]>> {
        const baseQuery = query(collection(this.db, this.userPlaylistCollectionName), where('user_id', '==', userId));
        return new Observable((observer) => {
            const unsubscribe = onSnapshot(baseQuery, (querySnapshot) => {
                const playlists: Playlist[] = [];
                querySnapshot.forEach((doc) => {
                playlists.push(doc.data() as Playlist);
                });
                observer.next(playlists);
            });
            return unsubscribe;
        });
    }

    //Get playlist by ID
    async getPlaylistById(playlistId: string): Promise<Playlist> {
        const docRef = doc(this.db, this.userPlaylistCollectionName, playlistId);
        const docSnap = await getDoc(docRef);
        return docSnap.data() as Playlist;
    }

    //Add song to playlist
    async addSongToPlaylist(playlistId: string, songId: string): Promise<void> {
        const playlistRef = doc(this.db, this.userPlaylistCollectionName, playlistId);
        await runTransaction(this.db, async (transaction) => {
            const playlistDoc = await transaction.get(playlistRef);
            if (!playlistDoc.exists()) {
                throw new Error('Playlist does not exist!');
            }
            transaction.update(playlistRef, {
                songs: arrayUnion(songId)
            });
        });
    }

    //Set the selected playlist and return a playlist snapshot with song in correct order
    async setUpSelectedPlaylistSnapshot(playlist: Playlist): Promise<PlaylistSnapshot> {
        this.playlistQueue = [];
        this.next_song = null;
        this.prev_song = null;
        //Separate songs into different categories
        let lessonSongs = [];
        let demoSongs = [];
        let lyricSongs = [];
        let otherSongs = [];
        let songPromises = playlist.songs.map(songID => this.songService.getSongById(songID));
        let songs = await Promise.all(songPromises);
        songs.forEach((song) => {
            if(song.lessons && song.lessons.length > 0){
                lessonSongs.push(song);
                this.playlistQueue.push(song);
            }
            else if (song.demoSong === true) {
                demoSongs.push(song);
                this.playlistQueue.push(song);
            }
            //Include valid non-demo songs in the player view if the user is premium
            else if(song.lyric_id && song.video_id) {
                lyricSongs.push(song);
                if(this.user && this.user.premium){
                    this.playlistQueue.push(song);
                }
            }
            else {
                otherSongs.push(song);
            }
            //Only include songs with lyrics or demoSongs in the queue
        });
        let new_song_list = lessonSongs.concat(demoSongs).concat(lyricSongs).concat(otherSongs);
        return this.createPlaylistSnapshot(playlist, new_song_list);
    }

    setUser(user: UserInterface): void {
        this.user = user;
    }

    setSongIndex(songId: string): void {
        if(!this._selected_playlist || !this.playlistQueue || this.playlistQueue.length <= 1){
            return null;
        }
        else {
            this.selectedSongIndex = this.playlistQueue.findIndex((song) => song.id === songId);
        }
    }

    createPlaylistSnapshot(playlist: Playlist, songList: Song[]): PlaylistSnapshot {
        let ownsPlaylist = false;
        if(this.user){
            ownsPlaylist = this.user.id == playlist.user_id;
        }
        return {
            id: playlist.id,
            name: playlist.name,
            description: playlist.description,
            songs: songList,
            user_id: playlist.user_id,
            user_name: playlist.user_name,
            imgURL: playlist.imgURL,
            premium: playlist.premium,
            userOwnsPlaylist: ownsPlaylist
        };
    }

    //Go to the next song in the playlist that is a demoSong or the user is premium
    nextSong() {
        if(!this._selected_playlist || !this.playlistQueue || this.playlistQueue.length <= 1){
            return null;
        }
        if(this.selectedSongIndex < this.playlistQueue.length - 1){
            this.next_song = this.playlistQueue[this.selectedSongIndex + 1];
        }
        else{
            this.next_song =  this.playlistQueue[0];
        }
    }

    //Go to the previous song in the playlist that is a demoSong or the user is premium
    prevSong() {
        if(!this._selected_playlist || !this.playlistQueue || this.playlistQueue.length <= 1){
            return null;
        }
        if(this.selectedSongIndex > 0){
            this.prev_song = this.playlistQueue[this.selectedSongIndex - 1];
        }
        else{
            this. prev_song =  this.playlistQueue[this.playlistQueue.length - 1];
        }
    }

    //Add song suggestin to Playlist
    async addSongSuggestionToPlaylist(playlistId: string, suggestionRef: SongSuggestionRef): Promise<void> {
        const playlistRef = doc(this.db, this.userPlaylistCollectionName, playlistId);
        await runTransaction(this.db, async (transaction) => {
            const playlistDoc = await transaction.get(playlistRef);
            if (!playlistDoc.exists()) {
                throw new Error('Playlist does not exist!');
            }
            transaction.update(playlistRef, {
                suggested_songs: arrayUnion(suggestionRef)
            });
        });
    }

    //Remove song from playlist
    async removeSongFromPlaylist(playlistId: string, songId: string): Promise<void> {
        const playlistRef = doc(this.db, this.userPlaylistCollectionName, playlistId);
        await runTransaction(this.db, async (transaction) => {
            const playlistDoc = await transaction.get(playlistRef);
            if (!playlistDoc.exists()) {
                throw new Error('Playlist does not exist!');
            }
            transaction.update(playlistRef, {
                songs: arrayRemove(songId)
            });
        });
    }

    //Remove suggestion ref from playlist
    async removeSuggestionFromPlaylist(playlistId: string, songRef: SongSuggestionRef): Promise<void> {
        const playlistRef = doc(this.db, this.userPlaylistCollectionName, playlistId);
        await runTransaction(this.db, async (transaction) => {
            const playlistDoc = await transaction.get(playlistRef);
            if (!playlistDoc.exists()) {
                throw new Error('Playlist does not exist!');
            }
                transaction.update(playlistRef, {
                    suggested_songs: arrayRemove(songRef)
                });
        });
    }

    //Create playlist from form
    async createPlaylist(playlist: PlaylistCreationInfo, user: UserInterface): Promise<string> {
        //Initialize new playlist
        const playlistRef = doc(collection(this.db, this.userPlaylistCollectionName));
        const new_playlist: Playlist = {
            id: playlistRef.id,
            name: playlist.name,
            description: playlist.description,
            songs: [],
            user_id: user.id,
            user_name: user.firstName,
            imgURL: 'assets/playlist-default.png',
            premium: false
        };
        await setDoc(playlistRef, new_playlist);
        return playlistRef.id;
    }

    //Remove playlist
    async removePlaylist(playlistId: string): Promise<void> {
        const playlistRef = doc(this.db, this.userPlaylistCollectionName, playlistId);
        await deleteDoc(playlistRef);
    }

}

