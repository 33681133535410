import { FirebaseApp } from 'firebase/app';
import {
  getFirestore,
  doc,
  Firestore,
  getDoc,
  setDoc,
  collection,
  onSnapshot
} from 'firebase/firestore';
import { FirebaseConfigService } from './firebase.config.service';
import { Song } from '../entities/song';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

export interface DynamicContent {
    songOfTheDay: string;
}


@Injectable({ providedIn: 'root' })
export class DynamicContentService {
    private app: FirebaseApp;
    private db: Firestore;
    private readonly dyanamicContentCollection = 'DynamicContent';

    constructor(private firebaseConfigService: FirebaseConfigService) {
        this.app = this.firebaseConfigService.app;
        this.db = getFirestore(this.app,'playlist-party');
    }
    public async getSongOfTheDay(siteId: string): Promise<string> {
        const siteContentRef = doc(this.db, this.dyanamicContentCollection, siteId);
        const siteContentDoc = (await getDoc(siteContentRef)).data() as DynamicContent;
    
        // Get the current date and format it as MMDD
        const date = new Date();
        const dayOfYear = ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
        // Look up the song of the day in siteContentDoc using dayOfYear as the key
        const songOfTheDay = siteContentDoc['songofthedaydict'][dayOfYear];
    
        return songOfTheDay;
    }

}
