import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { SiteService } from 'src/app/services/site.service';


export interface MailingListInfo {
  email: string,
  name: string,
  songOfTheDayUpdates: boolean,
};

@Component({
  selector: 'app-mailing-list-popup',
  templateUrl: './mailing-list-popup.component.html',
  styleUrls: ['./mailing-list-popup.component.scss'],
})
export class MailingListPopupComponent  implements OnInit {
  
  mailingForm!: FormGroup;

  playlistCreationInfo: MailingListInfo = {
    email: '',
    name: '',
    songOfTheDayUpdates: false,
  };


  constructor(private formBuilder: FormBuilder, private modalCtrl: ModalController,
    public siteService: SiteService) { }

  ngOnInit() {
    this.mailingForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
      description: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(100)]],
    });
  }

  async createPlaylist(){
    const formValues = this.mailingForm.value;
    this.playlistCreationInfo.name = formValues.name;
    //this.playlistCreationInfo.description = formValues.description;
    //Initialize new playlist
    this.modalCtrl.dismiss(this.playlistCreationInfo, 'create');
  }

  cancel() {
    return this.modalCtrl.dismiss(null,'cancel');
  }

}
