import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PlaylistCreationInfo } from '../entities/playlist';
import { ModalController } from '@ionic/angular';
import { SiteService } from '../services/site.service';

@Component({
  selector: 'app-playlist-creation-modal',
  templateUrl: './playlist-creation-modal.component.html',
  styleUrls: ['./playlist-creation-modal.component.scss'],
})
export class PlaylistCreationModalComponent  implements OnInit {

  playlistForm!: FormGroup;

  playlistCreationInfo: PlaylistCreationInfo = {
    name: '',
    description: '',
  };


  constructor(private formBuilder: FormBuilder, private modalCtrl: ModalController,
    public siteService: SiteService) { }

  ngOnInit() {
    this.playlistForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
      description: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(100)]],
    });
  }

  async createPlaylist(){
    const formValues = this.playlistForm.value;
    this.playlistCreationInfo.name = formValues.name;
    this.playlistCreationInfo.description = formValues.description;
    //Initialize new playlist
    this.modalCtrl.dismiss(this.playlistCreationInfo, 'create');
  }

  cancel() {
    return this.modalCtrl.dismiss(null,'cancel');
  }

}
