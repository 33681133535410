import { Component, Inject } from '@angular/core';
import { OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';
import { ChangeDetectorRef } from '@angular/core';
import { UserInterface } from './entities/user';
import { ModalController, AlertController } from '@ionic/angular';
import { PlaylistCreationModalComponent } from './playlist-creation-modal/playlist-creation-modal.component';
import { MailingListPopupComponent } from './shared-components/mailing-list-popup/mailing-list-popup.component';
import { Router } from '@angular/router';
import { PlaylistService } from './services/playlist.service';
import { SiteService } from './services/site.service';
import { DynamicContentService } from './services/dynamiccontent.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{
  constructor(private authService: AuthService,private cdf: ChangeDetectorRef,
    private userService: UserService, private modalCtrl: ModalController, 
    private alertController: AlertController, private router: Router,
    private playlistService: PlaylistService, public siteService: SiteService,
    private dynamicService: DynamicContentService) {
      //Create tracking script with hotjar
      const script = document.createElement('script');
      script.type
      script.innerHTML = 
      `(function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:5152095,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
      document.head.appendChild(script);
    }

  public songOfTheDay: string = "";

  isLoggedIn: boolean = false;
  user: UserInterface = {} as UserInterface;

  public alertButtons = [
    {
      text: 'Log In',
      role: 'confirm',
      handler: () => {
        this.router.navigate(['/sign-in']);
      },
    },
  ];

  ngOnInit(): void {
    this.setUpSite();
    this.authService.isLoggedInSnapShot.subscribe((isLogedIn) => {
      this.isLoggedIn = isLogedIn;
    });
    this.userService.user.subscribe(
      _ => this.setUpUser()
    );
    //this.createMailingListPopup();
  }

  setUpSite(){
    //Strip hostname of subdomains (test.spanishplaylistparty.com -> spanishplaylistparty.com)
    let hostname = window.location.hostname.split('.').slice(-2).join('.');
    this.siteService.setSiteID(hostname);
    document.title = this.siteService.site_title;
    let favicon = document.getElementById('appFavicon');
    if(favicon) {
      favicon.setAttribute('href', '../assets/icon/' + this.siteService.current_site_id + '/favicon-16x16.png');
    }
    this.dynamicService.getSongOfTheDay(this.siteService.current_site_id).then((songId) => {
      this.songOfTheDay = songId;
    });
  }

  setUpUser(){
    this.user = this.userService.userSnapShot;
  }
  
  logOut(){
    this.authService.logout();
    //Refresh the page
    window.location.reload();
  }

  async createPlaylist(){
    if(this.isLoggedIn){
      const modal = await this.modalCtrl.create({
        cssClass: this.siteService.current_site_id, //Sets the background of modal to theme
        component: PlaylistCreationModalComponent,
      });
      modal.present();

      const { data, role } = await modal.onWillDismiss();

      if (role === 'create') {
        this.playlistService.createPlaylist(data, this.user).then((playlist_id) => {
          this.router.navigate(['/playlist', playlist_id]);
        });
      }
    }
    else {
      const alert = await this.alertController.create({ header:"Log In To Create A Playlist!",
      message:"This feature is only available to logged-in users. Please log in to use this feature.",
      buttons:this.alertButtons})

      await alert.present();
    }
  }

  goToSongOfDay(){
    this.router.navigate(['/song', this.songOfTheDay]);
  }

  createMailingListPopup(){
    this.modalCtrl.create({
      component: MailingListPopupComponent,
      cssClass: 'mailing-list-popup',
      showBackdrop: false,
    }).then((modal) => {
      modal.present();
    });
  }
}
