
import { Injectable } from '@angular/core';
import { initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment';
import { getStorage, ref } from "firebase/storage";

@Injectable({providedIn: 'root'})
export class FirebaseConfigService {
    public app = initializeApp(environment.firebaseConfig);
}

